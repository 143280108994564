<template>
  <div class="information-list">
    <el-container style="padding: 1px">
      <el-aside class="left-con">
        <div class="tree-con">
          <OrgTree @refreshDataList="getList"></OrgTree>
        </div>
      </el-aside>
      <el-main>
        <div class="search-list">
          <div class="search-input">
            <el-input
              placeholder="请输入关键字查询"
              v-model="noticeTitle"
              class="input-with-select"
              style="width: 400px"
            >
              <el-button
                slot="append"
                @click="getList"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div>
          <el-button type="primary" @click="addSubject()">发布</el-button>
          <el-button
            type="danger"
            @click="deleteHandle"
            :disabled="dataListSelections.length <= 0"
            >删除</el-button
          >
        </div>
        <el-table
          :data="dataList"
          v-loading="dataListLoading"
          @selection-change="selectionChangeHandle"
          style="width: 100%; margin-top: 0"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column prop="tableName" label="标题">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="medium"
                @click="handleViewInfo(scope.row)"
                style="white-space: normal; user-select: unset"
              >
                {{ scope.row.noticeTitle }}
              </el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="createTime" label="创建时间"></el-table-column> -->
          <el-table-column
            prop="publishTime"
            width="200"
            label="发布时间"
          ></el-table-column>
          <el-table-column
            prop="noticeAuther"
            width="120"
            label="发布人"
          ></el-table-column>
          <el-table-column
            prop="orderWeight"
            label="排序权重"
            width="100"
          ></el-table-column>
          <el-table-column
            fixed="right"
            header-align="right"
            align="right"
            label="操作"
            width="150"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handleViewEdit(scope.row)">
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import OrgTree from "./tree/tree";

import qs from "qs";
export default {
  components: {
    OrgTree,
  },
  data() {
    return {
      noticeTitle: "",
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      lastType: "",
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      userId: "",
      unionId: "",
      copyDialog: false,
      url: "",
      lasttableModel: "",
      contentTypeSec: "",
      contentType: "",
      dataForm: {
        hospitalId: "", //医院id
        teamId: "", //团队id
        tableName: "", //表单名称
        tableBelongs: 1, //表单权限
        orderWeight: "", //权重
        tableType: "",
        tableModel: "",
        authorityType: 1,
      },
      rules: {
        tableName: [
          { required: true, message: "请输入表单名称", trigger: "blur" },
        ],
        authorityType: [
          {
            required: true,
            message: "请选择表单权限",
            trigger: "change",
          },
        ],
        tableModel: [
          { required: true, message: "请选择问卷分类", trigger: "change" },
        ],
      },
      array: [],
      menuList: [],
      defaultProps: {
        children: "list",
        label: "name",
        expandTrigger: "hover",
        checkStrictly: true,
        emitPath: true,
      },
      contentType: "",
      contentTypeSec: "",
      flag: 1,
    };
  },
  mounted() {
    if (localStorage.userInfo) {
      let userInfo = JSON.parse(localStorage.userInfo);
      this.userId = userInfo.id;
      this.unionId = userInfo.doctorUnionId;
    }
    this.getList();
  },
  methods: {
    handleChange(node) {
      console.log(node);
      if (node.length) {
        this.dataForm.tableModel = node[0];
        if (node[1]) {
          this.dataForm.tableType = node[1];
        } else {
          this.dataForm.tableType = "";
        }
        this.array = node;
      }
    },

    changeData(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].list = arr[i].map.list;
        if (arr[i].map.list != null && arr[i].map.list.length != 0) {
          this.changeData(arr[i].map.list);
        }
        if (arr[i].map.list.length < 1) {
          arr[i].list = undefined;
        }
      }
    },
    resetValue() {
      this.questionName = "";
      this.isPublish = "";
      this.isUse = "";
    },
    getList(d, n, lasttableModel) {
      console.log(d);
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let userInfo = {};
      if (localStorage.userInfo) {
        userInfo = JSON.parse(localStorage.userInfo);
      }
      let type = "";
      let typeSec = "";
      let tableBelongs = "";
      if (d) {
        this.pageIndex = 1;
        if (d.isEdit == false) {
          tableBelongs = 1;
        }
        if (d.type == "公告分类") {
          type = d.value;
          typeSec = "";
          this.lastType = "";
          this.contentType = d.value;
          this.contentTypeSec = "";
        } else {
          type = n.parent.data.value;
          typeSec = d.value;
          this.lastType = typeSec;
          this.contentType = n.parent.data.value;
          this.contentTypeSec = d.value;
        }
      } else {
        typeSec = this.lastType;
      }

      if (lasttableModel) {
        type = lasttableModel;
      } else {
        this.lasttableModel = type;
      }
      this.dataListLoading = true;

      this.$httpAes({
        url: this.$http.adornUrl("/notice/list"),
        method: "post",/*  */
        data: {
          intParam1: this.pageSize,
          intParam2: this.pageIndex,
          stringParam1: this.noticeTitle,
          intParam5: this.contentType,
          stringParam4: teamsId,
          stringParam5: enertyId,
          intParam4: 2, //2资讯
        },
      }).then(({ data }) => {
        if (data.status) {
          this.dataList = data.data;
          this.totalPage = data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    handelChange(val) {
      this.getList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getList(undefined, undefined, this.lasttableModel);
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getList(undefined, undefined, this.lasttableModel);
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },

    addSubject() {
      let routerUrl = this.$router.resolve({
        path: "/information/add",
        query: {
          contentType: this.contentType,
        },
      });
      window.open(routerUrl.href, "_blank");
    },

    handleViewEdit(row) {
      let routerUrl = this.$router.resolve({
        name: "addInformation",
        query: {
          id: row.id,
        },
      });
      window.open(routerUrl.href, "_blank");
    },
    handleViewInfo(row) {
      this.$router.push({
        name: "informationDetail",
        query: {
          id: row.id,
        },
      });
    },
    async deleteHandle() {
      this.$confirm("确定进行[删除公告]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          const id = ids.join(`,`);

          const { data: res } = await this.$httpAes({
            url: this.$http.adornUrl(`/notice/delete?ids=${id}`),
            method: "post",
			data:{
				stringParam1:id
			}
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getList();
              },
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.information-list {
  .left-con {
    width: 340px !important;
  }
  .search-list {
    .search-input {
      padding-bottom: 24px;
      .el-input {
        width: 400px !important;
      }
    }
  }
}
.tree-con {
  width: 100%;
  padding: 25px 0;
  height: 100%;
  border: 1px solid #eee;
}
</style>
